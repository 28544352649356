<template>
	<div class="carmodel">
		<div class="carmodel__edit" @click="handCarModel">
			{{carmodel.name}}
			<img src="@/assets/common/icon_bottom.png">
		</div>
		
		<!-- 车型选择弹窗 -->
		<van-popup v-model="show" position="bottom" :style="{'background-color':'#575757' }">
			<div class="carmodel__btns">
				<div @click="handCarModelClose">取消</div>
				<div @click="handCarModelOk">确定</div>
			</div>
			<div class="carmodel__list">
				<div
					:class="['carmodel__item', carmodel.id == item.id ? 'carmodel__item--active' : '']"
					v-for="item in carmodelList"
					:key="item.id"
					@click="handCarModelEdit(item)"
				>
					<div class="carmodel__item__logo">
						<img :src="item.car_image" mode="widthFix">
					</div>
					<div class="carmodel__item__name">
						{{item.name}}
					</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>

export default {
	name: 'CarModel',
	data() {
		return {
			carmodelTemp: {
				id: 0,
				name: '',
			},
			carmodel: {
				id: 0,
				name: '',
			},
			show:false,
			carmodelList: [],
		};
	},
	mounted(){
		this.getCarModelList()
	},
	methods: {
		// 切换车型
		handCarModel(e) {
			this.carmodelTemp = this.carmodel;
			this.show = true
		},
		handCarModelClose(e) {
			this.carmodel = this.carmodelTemp
			this.show = false
		},
		handCarModelEdit(e) {
			this.carmodel = e
		},
		handCarModelOk(e) {
			this.carmodelTemp = this.carmodel
			this.handCarModelClose()
			this.$emit('edit', this.carmodel.id)
		},
		
		// 车型列表
		async getCarModelList(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_reservation_car_model_image', {})
			if (result.code == 1) {
				result.data.res.forEach(v => {
					if (this.carmodel.id == 0) this.carmodel = v
					v.car_image = this.serverUrl(v.car_image)
					this.carmodelList.push(v)
				})
				this.$emit('edit', this.carmodel.id)
			}
		},
	}
}
</script>

<style lang="scss">
.carmodel__edit{
	margin: 0 auto;
	width: 670px;
	height: 92px;
	line-height: 92px;
	border: 1px solid #979797;
	text-align: center;
	position: relative;
	font-size: 32px;
	font-family: Regular;
	color: #fff;
	img{
		position: absolute;
		right: 30px;
		top: 30px;
		width: 32px;
		height: 32px;
	}
}
.carmodel__btns{
	padding: 46px 40px 0;
	display: flex;
	justify-content: space-between;
	div{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #B9B9B9;
		line-height: 36px;
	}
	div:last-child{
		color: #F3C846;
	}
}
.carmodel__list{
	padding: 54px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.carmodel__item{
		margin-top: 30px;
		width: 300px;
		box-sizing: border-box;
		padding: 40px 24px 16px;
		text-align: center;
		&__logo{
			img{
				width: 100%;
			}
		}
		&__name{
			margin-top: 12px;
			font-size: 30px;
			font-family: Regular;
			color: #ECECEC;
			line-height: 30px;
		}
	}
	.carmodel__item--active{
		background: rgba(0, 0, 0, 0.1);
		border-radius: 14px;
	}
}

</style>