<template>
	<div class="calendar">
		<div class="title">
			<div class="checks">
				<!-- <span @click="onCheckDate('reduction','year')" class="check-item">&lt;&lt;</span> -->
				<span @click="onCheckDate('reduction','month')" class="check-item">&nbsp;&lt;&nbsp;</span>
				<span class="check-item-date">{{getYearMonth}}</span>
				<span @click="onCheckDate('add','month')" class="check-item">&nbsp;&gt;&nbsp;</span>
				<!-- <span @click="onCheckDate('add','year')" class="check-item">&gt;&gt;</span> -->
			</div>
		</div>
		<div class="calendar-days">
			<div class="calendar-header">
				<span v-for="(item,index) in calendarHeader" :key="index" class="header-item">{{item}}</span>
			</div>
			<div class="day-row" v-for="row in 6" :key="row">
				<!-- ,'current-day':currentDay(visibleDays[(row-1)*7+line-1]) -->
				<span @click="onSelectDay(row,line)" class="day-line" :class="{'select-day':(row==selectDay.row&&line==selectDay.line&&!(notCurrentMonth(visibleDays[(row-1)*7+line-1]))) || (currentDay(visibleDays[(row-1)*7+line-1])&&!selectDay.row&&!selectDay.line ),'not-current-month':notCurrentMonth(visibleDays[(row-1)*7+line-1])}" v-for="line in 7" :key="line">
					{{visibleDays[(row-1)*7+line-1] | getDay}}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			calendarHeader: ["日", "一", "二", "三", "四", "五", "六"],
			nowDate: new Date(),
			selectDay: {
				row: null,
				line: null
			},
			time:null,
		}
	},
	computed: {
		// 当前日期换为年月
		getYearMonth() {
			const date = new Date(this.nowDate);
			const year = date.getFullYear();
			let month = date.getMonth();
			month = month + 1;
			month < 10 ? month = "0" + month : month = month;
			return `${year}年${month}月`;
		},
		visibleDays() {
			const { year, month } = this.getYearMonthDay(new Date(this.nowDate)); // 获取当前年月
			let currentFirstDay = new Date(year, month, 1); // 当前月份的第一天
			const week = currentFirstDay.getDay(); // 周几
			let startDay = currentFirstDay - week * 24 * 60 * 60 * 1000; // 开始时间
			// 循环42天（保证日历格式统一   6*7）
			let dayArr = new Array();
			for (let i = 0; i < 42; i++) {
				dayArr.push(new Date(startDay + i * 24 * 60 * 60 * 1000));
			};
			return dayArr;
		}
	},
	mounted(){},
	methods: {
		// 切换年月事件
		onCheckDate(mode, category) {
			this.selectDay.row = null;
			this.selectDay.line = null;
			const { year, month } = this.getYearMonthDay(new Date(this.nowDate));
			if (mode == "add") {
				if (category == "year") {
					this.nowDate = new Date(year + 1, month, 1);
				} else {
					this.nowDate = new Date(year, month + 1, 1);
				}
			} else {
				if (category == "year") {
					this.nowDate = new Date(year - 1, month, 1);
				} else {
					this.nowDate = new Date(year, month - 1, 1);
				}
			}
		},
		// 点击日期事件
		onSelectDay(row, line) {
			if((this.notCurrentMonth(this.visibleDays[(row-1)*7+line-1]))) return ''
			this.selectDay.row = row;
			this.selectDay.line = line;
			const time = new Date(this.visibleDays[(row-1)*7+line-1])
			this.time = this.timestampToTime(time)
			this.$emit('settime',this.time)
			console.log(row, line,this.timestampToTime(this.time),this.visibleDays[(row-1)*7+line-1])
		},
		// 判断哪个是当前日
		currentDay(date) {
			const currentDate = this.getYearMonthDay(new Date(date));
			const nowDate = this.getYearMonthDay(new Date());
			if (currentDate.year == nowDate.year && currentDate.month == nowDate.month && currentDate.day == nowDate.day) {
				return true;
			} else {
				return false;
			}
		},
		timestampToTime(timestamp) {
			var date = new Date(timestamp);
			var y = date.getFullYear() +'';
			var m = date.getMonth() + 1;
			m = m < 10 ? ('0' + m) : m + '';
			var d = date.getDate();
			d = d < 10 ? ('0' + d) : d + '';
			var h = date.getHours();
			var minute = date.getMinutes();
			minute = minute < 10 ? ('0' + minute) : minute;
			var second = date.getSeconds();
			second = second < 10 ? ('0' + second) : second + '';
			let Time = []
			Time.push(y)
			Time.push(m)
			Time.push(d)
			return Time
		},
		// 判断当前日期是不是本月
		notCurrentMonth(date) {
			if (this.getYearMonthDay(new Date(date)).month != this.getYearMonthDay(new Date(this.nowDate)).month) {
				return true;
			} else {
				return false;
			}
		},
		// 获取年月日
		getYearMonthDay(date) {
			const year = date.getFullYear();
			const month = date.getMonth();
			const day = date.getDate();
			return { year, month, day };
		}
	},
	filters: {
		// 日期转换为日
		getDay(date) {
			return new Date(date).getDate();
		}
	},
	mounted() {
	}
}
</script>

<style lang="scss" scoped>
.calendar {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	// 头部
	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		justify-content: center;
		padding: 0 15px;
		font-family: HYQiHeiY3-45;
    color: #fff;
		.check-item-date {
			margin: 0 10px;
		}
		.check-item {
			margin: 0 10px;
			cursor: pointer;
			user-select: none;
			display: inline-block;
			&:hover {
				// color: #000000;
				transform: scale(1.1);
			}
		}
	}
	// 日历
	.calendar-days {
		flex: 1;
		display: flex;
		flex-direction: column;
		.calendar-header {
			display: flex;
			margin: 10px 0;
			user-select: none;
			.header-item {
				display: block;
				font-family: HYQiHeiY3-45 !important;
				color: #9B9B9B;
				flex: 1;
				text-align: center;
				font-weight: bold;
			}
		}
		.day-row {
			flex: 1;
			display: flex;
			.day-line {
				display: flex;
				justify-content: center;
				align-items: center;
				flex: 1;
				height: 100px;
				cursor: pointer;
				transition: all 0.2s;
				font-family: HYQiHeiY3-45;
				color: #fff;
				user-select: none;
			}
			// 非本月
			.not-current-month {
				color: #6F6F6F !important;
			}
			// 当前日期
			.current-day {
				background-color: #2475ec;
				color: #fff;
				border-radius: 50%;
			}
			// 选中的日期
			.select-day {
				background-color: red !important;;
				border-radius: 50%;
			}
		}
	}
}
</style>
