<template>
	<div class="carlist">
		<!-- 车辆列表 -->
		<van-swipe
			v-if="carList.length > 1"
			:show-indicators="false"
			:loop="false"
			:display-multiple-items="3"
			:current="current"
			:width="125"
		>
			<van-swipe-item v-for="item in carList" :key="item.id" @click="handCar(item)">
				<div :class="['car', item.id == car.id && item.id != 0 ? 'car--active' : '',item.id == 0 && store_auth == 0?'opc':'']">
					<div class="car__name" v-if="item.id > 0">
						{{item.name}}
					</div>
					<div class="car__model" v-if="item.id > 0">
						{{item.model_name}}{{item.collocation_name}}
					</div>
					<div class="car__add" v-if="item.id == 0 && store_auth == 1">
						{{item.name}}
					</div>
				</div>
				
			</van-swipe-item>
			<van-swipe-item v-if="carList.length < 3"> </van-swipe-item>
		</van-swipe>
		
		<!-- 新增车辆 -->
		<div class="carlist__empty" v-if="carList.length <= 1">
			<img src="@/assets/common/icon_carempty.png">
			<div class="carlist__empty__init">
				暂无试驾车辆
			</div>
			<div class="carlist__empty__add" v-if="store_auth == 1" @click="handCarAdd">
				新增车辆
			</div>
		</div>
		
		<!-- 车辆新增弹窗 -->
		<van-dialog :show-confirm-button="false"  v-model="show" :is-mask-click="false">
			<div class="carddd">
				<div class="carddd__title">
					添加车牌
				</div>
				<div class="carddd__opts">
					<div class="carddd__opts__select" @click="handCarModel">
						{{carForm.modelName}}{{carForm.collocationName}}
						<img src="@/assets/common/icon_bottom.png">
					</div>
					<div class="carddd__opts__input">
						<input
							type="text"
							v-model="carForm.license"
							placeholder="点击输入车牌"
							placeholder-style="font-family: HYQiHeiY3-55;"
						/>
					</div>
				</div>
				<div class="carddd__btns">
					<div @click="handCarAddClose">取消</div>
					<div @click="handCarAddOk">确定</div>
				</div>
			</div>
		</van-dialog>
		
		<!-- 车型选择弹窗 -->
		<van-popup v-model="carAddshow" position="bottom" :style="{'background-color':'#575757' }">
			<div class="carmodel__btns">
				<div @click="handCarModelClose">取消</div>
				<div @click="handCarModelOk">确定</div>
			</div>
			<div class="carmodel__list">
				<van-picker
					swipe-duration="300"
					:columns="modelpicerlist"
					:value-key="'name'" 
					@change="handCarModelEdit"
					class="picker-div"
				>
				</van-picker>
			</div>
		</van-popup>
	</div>
</template>

<script>

export default {
	name: 'CarList',
	props: {
		carmodelId: Number,
		carId:Number,
	},
	data() {
		return {
			carmodelList: [],
			modelId: 0,
			collocationId: 0,
			modelList: [],
			collocationList: [],
			modelpicerlist:[],
			current: 0,
			car: {
				id: 0
			},
			carIdlist:[],
			carList: [],
			store_auth:'',
			carForm: {
				modelId: 0,
				modelName: '',
				collocationId: 0,
				collocationName: '',
				license: ''
			},
			// 控制弹框
			show:false,
			carAddshow:false,
		};
	},
	watch: {
		carmodelId(e) {
			this.getCarList()
		},
		modelId(e) {
			this.collocationList.length = 0
			this.carmodelList.filter(v => v.id == this.modelId)[0].scsj_car_collocation.forEach(v => {
				this.collocationList.push({
					id: v.id,
					name: v.name
				})
			})
			if (this.collocationList.length > 0) {
				this.collocationId = this.collocationList[0].id
			}
			if (this.collocationList.length > 0 && this.carForm.collocationId == 0) {
				this.carForm.collocationId = this.collocationList[0].id
				this.carForm.collocationName = this.collocationList[0].name
			}
		},
	},
	mounted(){
		this.store_auth = localStorage.getItem('store_auth')
		this.getCarModelList()
	},
	methods: {
		// 切换车辆
		handCar(e) {
			if (e.id == 0) {
				this.handCarAdd()
			} else {
				this.car = e
				this.$emit('edit', this.car.id)
			}
		},
		
		// 新增车辆
		handCarAdd(e) {
			this.carIdlist.forEach((v, k)=>{
				if (v.id == this.carmodelId) {
					this.modelId = v.id
					this.carForm.modelId = v.id
					this.carForm.modelName = v.name
				}
			})
			this.show = true
		},
		handCarAddClose(e) {
			this.show = false
		},
		async handCarAddOk(e) {
			const {data: result} = await this.$http.post('/api/scsj/add_car_license', {
				model_id: this.carForm.modelId,
				collocation_id: this.carForm.collocationId,
				license: this.carForm.license
			})
			if (result.code == 1) {
				this.handCarAddClose()
				this.getCarList()
			}
			if (result.code == 0) {
				this.$toast.fail(result.msg)
			}
		},
		
		// 车辆类型
		handCarModel(e) {
			this.carAddshow = true
		},
		handCarModelEdit(picker,value,index) {
			this.modelpicerlist.forEach(item => {
				if(item.name == value[0]){
					this.modelId = item.id
					item.children.forEach(v => {
						if(v.name == value[1]){
							this.collocationId = v.id
						}
					})
				}
			})
		},
		handCarModelClose(e) {
			this.carAddshow = false
		},
		handCarModelOk(e) {
			this.carForm.modelId = this.modelId
			this.carForm.collocationId = this.collocationId
			this.carForm.modelName = this.modelList.filter(v => v.id == this.carForm.modelId)[0].name
			this.carForm.collocationName = this.collocationList.filter(v => v.id == this.carForm.collocationId)[0].name
			this.handCarModelClose()
		},
		
		// 车辆列表
		async getCarList(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_car_license', {
				model_id: this.carmodelId
			})
			if (result.code == 1) {
				this.carList.length = 0
				this.car.id = 0
				if (result.data.res != '') {
					result.data.res.forEach(v => {
						if (this.car.id == 0) this.car = v
						this.carList.push(v)
					})
				} else {
					this.current = 1
				}
				this.carList.push({
					id: 0,
					name: '新增车辆',
				})
				this.$nextTick(() => {
					this.current = 0
				})
				this.$emit('edit', this.car.id)
			}
		},
		
		// 车型列表
		async getCarModelList(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_car_model_1_5', {
				store_id: 0
			})
			if (result.code == 1) {
				this.modelpicerlist = result.data.res.map(item => {
					item.children = item.scsj_car_collocation
					return item
				})
				this.carIdlist = result.data.res
				result.data.res.forEach((v, k) => {
					this.carmodelList.push(v)
					this.modelList.push({
						id: v.id,
						name: v.name
					})
					if (this.modelId == 0) {
						this.modelId = v.id
						this.carForm.modelId = v.id
						this.carForm.modelName = v.name
					}
				})
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.opc{
	display: none;
}
.carlist{
	margin: 40px auto 0;
	width: 750px;
	.car{
		margin-left: 15px;
		margin-right: 15px;
		border: 1px solid rgba(255, 255, 255, 0.3);
		text-align: center;
		padding: 28px 0;
		&__name{
			overflow: hidden;
			white-space:nowrap;
			margin-bottom: 10px;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			color: #fff;
			line-height: 40px;
		}
		&__model{
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #ECECEC;
			line-height: 32px;
		}
		&__add{
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			color: #fff;
			line-height: 82px;
		}
	}
	.car--active{
		background: #FA0037;
		border: 1px solid #FA0037;
	}
	&__empty{
		padding-bottom: 50px;
		text-align: center;
		img{
			width: 88px;
			height: 54px;
		}
		&__init{
			margin-top: 26px;
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #575757;
			line-height: 34px;
		}
		&__add{
			margin: 98px auto 0;
			width: 260px;
			height: 72px;
			border: 1px solid rgba(151, 151, 151, 0.3);
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #ECECEC;
			line-height: 72px;
		}
	}
}
.carddd{
	background: #E8E8E8;
	width: 100%;
	padding: 0 10px;
	box-sizing: border-box;
	border-radius: 20px;
	&__title{
		padding-top: 60px;
		text-align: center;
		font-size: 40px;
		font-family: HYQiHeiY3-65;
		color: #101010;
		line-height: 40px;
	}
	&__opts{
		margin-top: 60px;
		&__select{
			margin: 0 auto;
			width: 590px;
			height: 92px;
			line-height: 92px;
			border: 1px solid rgba(87 ,87 , 87, 0.3);
			text-align: center;
			position: relative;
			font-size: 32px;
			font-family: HYQiHeiY3-55;
			color: #333;
			img{
				position: absolute;
				right: 30px;
				top: 30px;
				width: 32px;
				height: 32px;
			}
		}
		&__input{
			margin: 20px auto 0;
			width: 590px;
			height: 92px;
			border: 1px solid rgba(87 ,87 , 87, 0.3);
			input{
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				text-align: center;
				font-size: 32px;
				border: none;
				background-color: rgba(247, 246, 246, 0);
				font-family: HYQiHeiY3-55;
				// color: #333;
			}
		}
	}
	&__btns{
		margin-top: 60px;
		border-top: 1px solid #D7D7D7;
		display: flex;
		div{
			text-align: center;
			width: 50%;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			color: #323232;
			line-height: 90px;
		}
		div:first-child{
			border-right: 1px solid #D7D7D7;
		}
		div:last-child{
			color: #2C28E8;
		}
	}
}
.carmodel__btns{
	padding: 46px 40px 0;
	display: flex;
	justify-content: space-between;
	div{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #B9B9B9;
		line-height: 36px;
	}
	div:last-child{
		color: #F3C846;
	}
}
.carmodel__list{
	padding: 0 54px 200px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.carmodel__item{
		margin-top: 30px;
		width: 300px;
		text-align: center;
		&__logo{
			img{
				width: 100%;
			}
		}
		&__name{
			margin-top: 12px;
			font-size: 30px;
			font-family: Regular;
			color: #ECECEC;
			line-height: 30px;
		}
	}
	.carmodel__item--active{
		background: rgba(0, 0, 0, 0.1);
		border-radius: 14px;
	}
}
.picker-div {
	width: 100%;
}
.carmodel__list__item {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 40px;
	font-family: HYQiHeiY3-65;
	color: #fff;
}
</style>