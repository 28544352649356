<template>
	<div class="reservation">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="预约"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		right-text="分享本页"
		right-arrow
		@click-right="onshare"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		<!-- 日历 -->
		<!-- <uni-calendar
			class="uni-calendar--hook"
			:selected="info.selected"
			:showMonth="false"
			@change="change"
			@monthSwitch="monthSwitch"
		/> -->
		<Calender @settime="gettime"></Calender>
		<!-- 车型选择 -->
		<div style="height: 40px;"></div>
		<CarModel v-on:edit="handCarModel"></CarModel>
		
		<!-- 类型选择 -->
		<div class="reservation__type">
			<img v-if="reservationType == 0" src="@/assets/common/icon_switch.png" @click="reservationType = 1">
			<img v-if="reservationType == 1" src="@/assets/common/icon_switched.png" @click="reservationType = 0">
			<span>仅显示我的预约</span>
		</div>
		
		<!-- 车辆选择 -->
		<CarList :carId="carId" :carmodelId="carmodelId" v-on:edit="handCar"></CarList>
		
		<!--- 日期 -->
		<div class="reservation__list">
			<div
				:class="['reservation__item', item.used == 1 ? 'reservation__item--disabled' : '']"
				v-for="item,key in reservationList"
				@click="opencarModelPopup(item)"
				:key="item.id"
				v-if="key < 6"
			>
				{{item.time_range}}
			</div>
			<div class="reservation__line"></div>
			<div
				:class="['reservation__item', item.used == 1 ? 'reservation__item--disabled' : '']"
				v-for="item,key in reservationList"
				:key="item.id"
				v-if="key >= 6"
				@click="opencarModelPopup(item)"
			>
				{{item.time_range}}
			</div>
		</div>

		<van-popup v-model="carModelPopup" class="carmodel__reservation"  position="bottom" >
			<div class="time">
				{{ fulldate[0] + '年'+ fulldate[1] +  '月'+ fulldate[2] +'日  ' + tantime }}
			</div>
			<div v-if="reportuserinfo != null">
				<div class="title">
					车辆预约情况
				</div>
				<div class="user_info">
					<img :src="reportuserinfo.avatar" alt="">
					<div class="username">{{ reportuserinfo.username }}</div>
					<div class="concat">预约了本时段用车</div>
				</div>
				<div class="footer">
					<div class="invite_user" v-if="reportuserinfo.invitation != null && reportuserinfo.scsj_customer_log == null">试乘试驾邀请</div>
					<div class="invite_user" v-if="reportuserinfo.scsj_customer_log != null">试乘试驾</div>
					<div class="invite_time" v-if="reportuserinfo.invitation != null && reportuserinfo.scsj_customer_log == null">邀请函发出时间:{{ reportuserinfo.invitation.createtime }}</div>
					<div class="invite_time" v-if="reportuserinfo.scsj_customer_log != null">{{ reportuserinfo.scsj_customer_log.condition == 1?'进行中':'已完成' }}</div>
				</div>
			</div>
			<div v-else class="wuyuyue">
				无预约
			</div>
		</van-popup>

	</div>
</template>

<script>
import CarModel from '@/components/carmodel.vue'
import CarList from '@/components/carlist.vue'
import Calender from '@/components/calender.vue'
import { getDateTime4,getsignature,getDateTime2 } from '@/utils/utils.js'
	
export default {
	components: {
		CarModel,
		CarList,
		Calender
	},
	data() {
		return {
			scrollTop: 0,
			carmodelId: 0,
			carId: 0,
			reservationType: 0,
			fulldate: '',
			reservationList: [],
			carModelPopup:false,
			tantime:'',
			reportuserinfo:null,
			store_auth:'',
		}
	},
	mounted() {
		this.fulldate = getDateTime4().split('-')
	},
	watch: {
		reservationType(e) {
			this.getReservationList()
		},
	},

	methods: {
		async opencarModelPopup(v){
			this.reportuserinfo = null
			console.log(v)
			this.carModelPopup = true
			this.tantime = v.time_range
			if(v.reservation_log_id == null){
				
			}else{
				const {data:res} = await this.$http.post('/api/scsj/get_reservation_log',{reservation_log_id:v.reservation_log_id})
				if(res.code == 1){
					this.reportuserinfo = res.data.res
					this.reportuserinfo.avatar = this.serverUrl(this.reportuserinfo.avatar)
					if(this.reportuserinfo.invitation != null){
						this.reportuserinfo.invitation.createtime = getDateTime2(this.reportuserinfo.invitation.createtime)
					}
				}
			}
			
		},
		onshare(){
			const form = {}
			form.store_id = localStorage.getItem('scsjStoreId')
			form.year = this.fulldate[0]
			form.month = this.fulldate[1]
			form.day = this.fulldate[2]
			const options = JSON.stringify(form)
			const url = localStorage.getItem('share')
			this.$wx.invoke(
						"shareAppMessage", {
							title: '车辆预约概况', // 分享标题
							desc: '', // 分享描述
							link: url+`/scsj/scsjyygk/?year=${form.year}&month=${form.month}&day=${form.day}`, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
							imgUrl: 'https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/logo.jpeg', // 分享图标
							enableIdTrans: 0, // 是否开启id转译，不填默认为0
						}, function(res) {
								if (res.err_msg == "shareAppMessage:ok") {
									this.$toast.success('分享成功')						//正确处理
								}else {
									getsignature()					//错误处理
								}
						}
					);
		},
		gettime(val){
			this.fulldate = val
			console.log(fulldate)
			this.getReservationList()
		},
		change(e) {
			this.fulldate = e.fulldate
			this.getReservationList()
		},
		monthSwitch(e) {},
		// 切换车型
		handCarModel(e) {
			this.carmodelId = e
		},
		// 切换车辆
		handCar(e) {
			this.carId = e
			this.getReservationList()
		},
		// 预约列表
		async getReservationList(e) {
			this.reservationList.length = 0
			if (this.carId == 0) return
			const url = this.reservationType == 1 ? 
			'/api/scsj/get_user_reservation_list' : '/api/scsj/get_reservation_list'
			const {data: result} = await this.$http.post(url, {
				year: this.fulldate[0],
				month: this.fulldate[1],
				day: this.fulldate[2],
				license_id: this.carId
			})
			if (result.code == 1) {
				result.data.res.forEach(v => {
					this.reservationList.push(v)
				})
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.reservation__type{
	margin-top: 40px;
	padding: 0 40px;
	height: 40px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: 32px;
	font-family: HYQiHeiY3-55;
	color: #fff;
	img{
		width: 60px;
		height: 30px;
		margin-right: 10px;
	}
}
.reservation__list{
	padding: 40px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.reservation__item{
		margin-bottom: 32px;
		width: 314px;
		height: 88px;
		text-align: center;
		line-height: 88px;
		border: 1px solid rgba(255, 255, 255, 0.3);
		font-size: 32px;
		font-family: Regular;
		color: #fff;
	}
	.reservation__item--disabled{
		background: #6F6F6F;
		border: 1px solid #6F6F6F;
	}
	.reservation__line{
		width: 100%;
		height: 1px;
		background: #686868;
		margin-bottom: 32px;
	}
}

.carmodel__reservation{
	width: 750px;
	height: 628px;
	background: #575757;
	box-sizing: border-box;
	border-radius: 23px 23px 0px 0px;
	padding: 40px;
	font-family: HYQiHeiY3-55;
	.time{
		text-align: center;
		height: 34px;
		font-size: 32px;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 34px;
	}
	.title{
		height: 44px;
		font-size: 40px;
		font-weight: normal;
		color: #FFFFFF;
		text-align: center;
		line-height: 44px;
		margin-top: 100px;
	}
	.user_info{
		display: flex;
		align-items: center;
		margin-top: 40px;
		img{
			width: 72px;
			height: 72px;
			border-radius: 50%;
		}
		.username{
			height: 36px;
			font-size: 36px;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 36px;
			margin-left: 12px;
		}
		.concat{
			height: 36px;
			font-size: 28px;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 36px;
			opacity: 0.5;
			margin-left: 20px;
		}
	}
	.footer{
		width: 686px;
		height: 120px;
		background: #6F6F6F;
		border-radius: 12px;
		margin-top: 40px;
		padding: 24px 40px;
		box-sizing: border-box;
		.invite_user{
			height: 36px;
			font-size: 36px;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 36px;
		}
		.invite_time{
			height: 36px;
			font-size: 28px;
			font-family: HYQiHeiY3-DEW, HYQiHeiY3;
			font-weight: normal;
			color: #FFFFFF;
			opacity: 0.5;
			line-height: 36px;
		}
	}
	.wuyuyue{
		font-size: 32px;
		font-weight: normal;
		color: #FFFFFF;
		opacity: 0.5;
		line-height: 34px;
		height: 500px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>
